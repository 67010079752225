import { useEffect } from "react";
import EachTrigger from "./EachTrigger";
import { connect } from 'react-redux';
import { fetchTriggers } from "../../actions/triggerAction";
import EachFolder from "./EachFolder";
import Loading from "../common/Loading";


const TriggerFoldersApp = (props) => {

	useEffect(() => {
		props.fetchTriggers();
	},[]);


    return (
		props.triggerIsLoading ?
			<Loading/> :
			<>
				{ 
					props.triggerFolders.map((elem,index) => {
						return <EachFolder key={index} folder={elem}/>
					}) 
				}

				{
					props.triggerWithoutFolders.map((elem,index) => {
						return <EachTrigger trigger={elem}/>
					})
				}
			</>
	);
}

const mapStateToProps = state => ({
    triggerFolders: state.triggerReducer.triggerFolders,
	triggerWithoutFolders : state.triggerReducer.triggerWithoutFolders,
	triggerIsLoading : state.triggerReducer.isLoading,
});

const mapDispatchToProps = dispatch => {
    return {
        fetchTriggers: (params) => dispatch(fetchTriggers(params))
    };
}

const TriggerFolders = connect(mapStateToProps, mapDispatchToProps)(TriggerFoldersApp);
 
export default TriggerFolders;