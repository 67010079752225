import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { Editor } from '@tinymce/tinymce-react';
import If from 'if-else-react';
import GlobalDropdown, { GlobalDropDownButton, GlobalDropDownItem } from '../globals/Dropdown/GlobalDropdown';
import { Button } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { image_upload_handler } from "../../api/rootApi";

const emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;

const customPersonalizedStyle = {
    container : () => ({
        marginLeft : 15,
        position : 'relative'
    }),
    input : () => ({
        height : 40
    }),
    menu : () => ({
        top : 'auto',
        position: 'absolute',
        zIndex : 99999,
        background : '#fff',
        width : '100%'
    })
}

const TextEditor = (props) => {

    const messageInputRef = useRef(null)
    const [editorState, setEditorState] = useState('');
    const [checked, setChecked] = useState(false);


    useEffect(() => {
        let contentBlock = '';
        if (props.appointmentUrl !== '' || props.userSignature !== '') {
            contentBlock = `${props.appointmentUrl ? props.appointmentUrl : ''}<p></p>${props.userSignature ? props.userSignature : ''}<p></p>`;
        }

        if(props.editSettings === true){
            contentBlock = props.updateComponent;
        }

        setEditorState(contentBlock);
    },[]);

    const handleEditorChange = (content, editor) => {
        setEditorState(content);
        props.action(content);
    }

    const handleSubjectChange = (subject) => {
        if(props.subjectAction){
            props.subjectAction(subject);
        }
    }

    const addTemplate = (subject,body) => {
        const contentBlock = body || "<p></p>";
        handleEditorChange(contentBlock,'');
        handleSubjectChange(subject);
    };

    const addSelectedBox = (options) => {
        messageInputRef.current.insertContent(options.value);
    }

    const renderTemplates = () => {
        return props.templates.map((list, index)  => (
            <GlobalDropDownItem key={index} title={list.label} onClickHide={true} onClick={() => addTemplate(list.subject,list.value)}/>
        ));
    }

    const handleCheck = (e) => {
        let newCheck = !checked;
        setChecked(newCheck);
        props.handleCheck(newCheck);
    };

    return (
        <div>
            <div className="editor">
                <div className="d-flex email-form-field align-items-center border-bottom-0 row">
                    <If condition={props.isShowSaveTemplate}>
                        <div className="select col s6">
                            <GlobalDropdown>
                                <GlobalDropDownButton>
                                    <Button startIcon={<ArrowBackIos/>} className={'used_template_button'} variant="outlined">Used templates</Button>
                                </GlobalDropDownButton>
                                { renderTemplates() }
                            </GlobalDropdown>
                        </div>
                    </If>
                    <div className="select text-editor-personalize col s6">
                        <Select
                            styles={customPersonalizedStyle}
                            name="personalize"
                            value = {null}
                            options={props.customFields}
                            placeholder={'Personalize'}
                            onChange={addSelectedBox}
                        />
                    </div>
                    {props.isShowSaveTemplateOption != undefined && props.isShowSaveTemplateOption &&
                    <div className="ml-auto save-template">
                        <label className="m-checkbox m-0 pr-2">
                            <input type="checkbox" name="save-template" onChange={() => handleCheck()}
                                   defaultChecked={checked}/> Save as a Quick Reply
                            <span></span>
                        </label>
                    </div>
                    }
                </div>
                <div className="editor-writing-field mt-2">
                    <input id="my-file" type="file" name="my-file" style={{display:"none"}} onChange="" />
                    <Editor
                        onInit={(evt, editor) => messageInputRef.current = editor}
                        apiKey='bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr'
                        value={editorState}
                        cloudChannel='5-dev'
                        textareaName='messagete'
                        init={{
                            height: 500,
                            browser_spellcheck: true,
                            menu: {
                                favs: {title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons'}
                            },
                            menubar: 'favs file edit view insert format tools table help',
                            plugins: [
                                'advlist autolink lists link image imagetools hr charmap print preview anchor pagebreak',
                                'searchreplace visualblocks visualchars code fullscreen media nonbreaking',
                                'insertdatetime media table contextmenu paste code help wordcount template'
                            ],
                            convert_urls: false,
                            toolbar:
                                'insertfile undo redo | styleselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | link image | \
                                bullist numlist outdent indent | removeformat | print preview media fullpage | help',
                            imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
                            imagetools_proxy: emailUploadFileApi,
                            images_upload_handler: image_upload_handler,
                            images_upload_url: emailUploadFileApi,
                            file_picker_types: 'file image media'
                        }}
                        onEditorChange={handleEditorChange}
                    />

                </div>
            </div>
        </div>
    );
}

export default TextEditor;
