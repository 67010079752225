const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/trigger`
const contactModuleUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const profileModuleUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/profile`

const Api = {
    triggerList: `${rootUrl}/list`,
    agencyTemplates: `${rootUrl}/agency-templates`,
    cloneAgencyTemplates: `${rootUrl}/clone-agency-templates`,
    changeStatus: `${rootUrl}/change-status`,
    duplicate: `${rootUrl}/duplicate`,
    remove: `${rootUrl}/remove`,
    shareLinkCreate: `${rootUrl}/share-link-create`,
    shareWithFriend: `${rootUrl}/share-link-with-friend`,
    add: `${rootUrl}/add`,
    edit: `${rootUrl}/edit`,
    folderAdd: `${rootUrl}/folder/add`,
    folderEdit: `${rootUrl}/folder/edit`,
    folderRemove: `${rootUrl}/folder/remove`,
    moveRuleToFolder: `${rootUrl}/move-rule-to-folder`,
    moveRuleFromFolder: `${rootUrl}/remove-rule-from-folder`,
    subUsersList: `${rootUrl}/sub-user-list`,
    cloneTriggerAndAssignToUser: `${rootUrl}/clone-trigger-and-assign-to-user`,
    settingGet: `${rootUrl}/setting-get`,
    settingSave: `${rootUrl}/setting-save`,
    customFieldsGet: `${rootUrl}/custom-fields-get`,
    getCollaboratorListDropdown: `${profileModuleUrl}/get-team-users-without-template`,
}

export default Api;