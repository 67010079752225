import React,{Component} from 'react';
import {connect} from "react-redux";
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import TextEditor from './TextEditor';
import GetTimeOptions from './GetTimeOptions';
import GetDurationOptions from './GetDurationOptions';
import SelectTask from './SelectTask';
import { setRequiredError, setTriggerAction, setTriggerActionValue } from '../../actions/triggerAction';
import { ACTION_TYPES, PACKAGE_PAGE_URL, TRIGGER_TYPES } from '../../constants/CoreConstants';
import If from 'if-else-react';
// import ShowNotification from '../common/ShowNotification';
import { Button, FormControl,MenuItem,Select as MaterialSelect,FormGroup,InputLabel,TextField, FormLabel, RadioGroup, Radio, FormControlLabel, Typography } from '@material-ui/core';
import { iconList } from '../globals/IconList';
import BootstrapTooltip from '../globals/BootstrapTooltip';
import Utils from '../../helpers/Utils';
import { sendWebhookTest } from '../../api/rootApi';
import { getCollaboratorDropdownList } from '../../api/triggerApi';
import SmartWebhookDrawer from "../trigger/smartWebhookBuilder/SmartWebhookDrawer";


const customPersonalizedStyle = {
    container : () => ({
        marginLeft : 15,
        position : 'relative'
    }),
    input : () => ({
        height : 40
    }),
    menu : () => ({
        top : 'auto',
        position: 'absolute',
        zIndex : 99999,
        background : '#fff',
        width : '100%'
    })
}

const customEmailPersonalizedStyle = {
    input : () => ({
        height : 40
    })
}

const customMessagePersonalizedStyle = {
    input : () => ({
        height : 40
    }),
    container : () => ({
        width : 200,
        position : 'relative'
    }),
}

const mapStateToProps = state => {
    return {
        loading : state.triggerSettingReducer.loading,
        triggerRule: state.triggerSettingReducer.triggerRule,
        allActionItem : state.triggerSettingReducer.allActionItem,
        allCampaign : state.triggerSettingReducer.allCampaign,
        allTag : state.triggerSettingReducer.allTag,
        allPipeline : state.triggerSettingReducer.allPipeline,
        allStage : state.triggerSettingReducer.allStage,
        allVirtualNumber : state.triggerSettingReducer.allVirtualNumber,
        allEmail : state.triggerSettingReducer.allEmail,
        allList : state.triggerSettingReducer.allList,
        profile : state.triggerSettingReducer.profile,
        agency : state.triggerSettingReducer.agency,
        allCollaborator : state.triggerSettingReducer.allCollaborator,
        data : state.triggerSettingReducer.data,
        email_templates : state.triggerSettingReducer.email_templates,
        allIntegrations : state.triggerSettingReducer.allIntegrations,
        triggerActionValue : state.triggerSettingReducer.triggerActionValue,
        allSources: state.triggerSettingReducer.allSources,
        triggerItemId: state.triggerSettingReducer.triggerItemId
    };
};

function mapDispatchToProps(dispatch) {
    return {
        setTriggerAction: (triggerActionId) => dispatch(setTriggerAction(triggerActionId)),
        setTriggerActionValue: (param) => dispatch(setTriggerActionValue(param)),
        setRequiredError: (param) => dispatch(setRequiredError(param)),
    };
}

class SingleAction extends Component {
    constructor(props) {
        super(props);
	    this.state = this.getInitialState();
    }

    componentDidUpdate(prevProps,prevState) {
	    if(this.props != prevProps){
            if(this.props.selectedActionValue){
                this.loadData(this.props.selectedActionValue,'update');
            }else{
                this.setState(this.getInitialState());
            }
        }
	}

    componentDidMount() {
        if(this.props.actionValue){
          this.loadData(this.props.actionValue,'did');
        }
        if(this.props.newAction){
          this.setState({selected_action_id: 0})
        }
    }

	getInitialState = () => {
        return {
	        show : false,
            selected_action_id: 0,
            selected_action: {},
            selected_action_values: {},
            selected_campaign_to_take_action: null,
            selected_tag_to_take_action: null,
            selected_list_to_take_action: null,
            selected_collaborator_to_take_action: null,
            selected_pipeline_to_take_action: null,
            selected_stage_to_take_action: null,
            defaultCampaignOptions : [],
            defaultTagOptions : [],
            defaultListOptions : [],
            defaultCollaboratorOptions : [],
            templateCollaboratorList: [],
            defaultPipelineOptions : [],
            defaultStageOptions : [],
            deal_value:0,
            deal_closing_day:0,
            createOrMove:0,
            deleteType:1,
            sms: '',
            mySmsCount: 0,
            fromNumber:null,
            to_sms_type:0,
            to_sms:null,
            note: '',
            from_email: null,
            to_email_type:0,
            to_email:null,
            email_subject : '',
            email_body : '',
            textEditor : '',
            webhook_url: '',
            zapier_webhook_url: '',
            title:'',
            task_description:'',
            time:'12:00 PM',
            duration:'00:15',
            taskTitle:'call',
            task_id:1,
            assign_to:null,
            day: 1,
            integration_id: null,
            defaultSourceOptions:[],
            selected_source_to_take_action: null,

            /* for twilio error code */
            twilio_sms_send_user: '',
            twilio_email_send_user: '',
            selectedSmartWebhook: null,
            isSubmittingAddSmartWebhookForm: false,
            openSmartWebhookBuilder: false,
            smart_webhook_data: null
        };
  }

    actionAddToCampaign = (selectedOption) => {
        this.setState({
            selected_campaign_to_take_action:selectedOption
        },() => this.setTriggerActionValue())
    };

    actionAddToSource = (selectedSource) => {
         this.setState({
            selected_source_to_take_action:selectedSource
        },() => this.setTriggerActionValue())
    }

    actionAddToTag = (selectedOption) => {
        this.setState({
            selected_tag_to_take_action:selectedOption
        },() => this.setTriggerActionValue())
    };

    actionAddToList = (selectedOption) => {
        this.setState({
            selected_list_to_take_action:selectedOption
        },() => this.setTriggerActionValue())
    };

    actionAddToCollaborator = (selectedOption) => {
        this.setState({
            selected_collaborator_to_take_action:selectedOption
        },() => this.setTriggerActionValue())
    };

    actionAddToPipeline = (selectedOption) => {
        this.setState({
            selected_pipeline_to_take_action:selectedOption
        });
        if(this.state.selected_pipeline_to_take_action != null && this.state.selected_pipeline_to_take_action.value != selectedOption.value){
            this.setState({
                selected_stage_to_take_action: null
            },() => this.setTriggerActionValue());
        }
        this.getDefaultOptionForStage(selectedOption.value);

    };

    actionAddToStage = (selectedOption) => {
        this.setState({
            selected_stage_to_take_action:selectedOption
        },() => this.setTriggerActionValue())
    };

    getDefaultOptionForCampaign = () => {
        let allOptions = [];
        if (this.props.allCampaign.length > 0) {

            allOptions = this.props.allCampaign.map(item => {
                return {
                    value: item.id,
                    label: item.title
                }
            });
        }
        this.setState({
            defaultCampaignOptions: allOptions
        })
    }

    getDefaultOptionForSource = () => {
        
        let tempAllSources = [];

        if (this.props.allSources.length > 0) {
            tempAllSources = this.props.allSources.map(item => {
                return {
                    value: item.id,
                    label: item.source_title
                }
            });
        }
        this.setState({
            defaultSourceOptions: tempAllSources
        })
    }

    getDefaultOptionForTag = () => {
        let allOptions = [];
        if (this.props.allTag.length > 0) {
            allOptions = this.props.allTag.map((data, index) => {
                return {
                    value: data.id,
                    label: data.name
                }
            });
        }
        this.setState({
            defaultTagOptions: allOptions
        })
    };

    getDefaultOptionForList = () => {
        let allOptions = [];
        if (this.props.allList.length > 0) {
            allOptions = this.props.allList.map((data, index) => {
                return {
                    value: data.id,
                    label: data.title
                }
            });
        }
        this.setState({
            defaultListOptions: allOptions
        })
    };

    getDefaultOptionForCollaborator = () => {
        let allOptions = [];
        if (this.props.allCollaborator.length > 0) {
            allOptions = this.props.allCollaborator.map((data, index) => {
                if (data.status == 1) {
                    return {
                        value: data.id,
                        label: data.full_name
                    }
                }
            });
        }
        this.setState({
            defaultCollaboratorOptions: allOptions
        })
    }

    getTemplateCollaboratorList = () =>{ //templateCollaboratorList
        getCollaboratorDropdownList()
        .then(response => {
            if(response.data.data.length>0){
                let tempData = response.data.data.map((data)=> (
                    {value: data.id, label: data.full_name}
                ));
                this.setState({
                    templateCollaboratorList: tempData
                })  
            }
        })
        .catch(error => {
            console.log('something is wrong' + error)
            
        });
    }

    getCollaboratorList = () => {
        if (this.props.allCollaborator.length > 0) {
            return this.props.allCollaborator.map((data, index) => {
                if (data.status == 1) {
                    return <MenuItem className="dropdownhelper-menuitem-class" value={data.id} key={index}>{data.full_name}</MenuItem>
                }
            });
        }
    }

    getIntegrationList = () => {
        if (this.props.allIntegrations.length > 0) {
            return this.props.allIntegrations.map((data, index) => {
                return <option value={data.app_integrations[0].id} key={index}>{data.app.name}</option>
            });
        }
    }

    getDefaultOptionForPipeline = () => {

        let allOptions = [];
        if (this.props.allPipeline.length > 0) {
            allOptions = this.props.allPipeline.map((data, index) => {
                return {
                    value: data.id,
                    label: data.title
                }
            });
        }
        this.setState({
            defaultPipelineOptions: allOptions
        })
    }

    getDefaultOptionForStage = (pipelineId) => {
        let allOptions = [];
        if (this.props.allStage.length > 0) {
            allOptions = this.props.allStage.filter( data => {
                return data.pipeline_id == pipelineId
            }).map(item => {
                return {
                    value: item.id,
                    label: item.stage
                }
            });
        }
        this.setState({
            defaultStageOptions: allOptions
        })
    }

    getEachAction = (allActionItem) => {
        var data = [];
        if(allActionItem.length > 0) {
          allActionItem.forEach((item, index) => {
            if(this.props.triggerItemId === TRIGGER_TYPES.SMS_ACCOUNT_SUSPENDED){
              if(
                item.key_index === ACTION_TYPES.TWILIO_PAUSE_ALL_CAMPAIGN ||
                item.key_index === ACTION_TYPES.TWILIO_PAUSE_ALL_COMMUNICATION ||
                item.key_index === ACTION_TYPES.TWILIO_EMAIL_USER ||
                item.key_index === ACTION_TYPES.TWILIO_SMS_USER
              ){
                data.push(
                  <MenuItem 
                    className="dropdownhelper-menuitem-class color__trigger_select" 
                    onClick={()=>{this.changeValue(item.key_index,item)}} 
                    key={index} 
                    value={item.key_index}
                  >
                    {item.name}
                  </MenuItem>
                )
              }
            }
            else if(this.props.triggerItemId === TRIGGER_TYPES.TWILIO_MESSAGE_FILTER){
              if(
                item.key_index === ACTION_TYPES.TWILIO_EMAIL_USER ||
                item.key_index === ACTION_TYPES.TWILIO_SMS_USER ||
                item.key_index === ACTION_TYPES.TWILIO_PAUSE_SPECIFIC_CAMPAIGN
              ){
                data.push(
                  <MenuItem 
                    className="dropdownhelper-menuitem-class color__trigger_select" 
                    onClick={()=>{this.changeValue(item.key_index,item)}} 
                    key={index} 
                    value={item.key_index}
                  >
                    {item.name}
                  </MenuItem>
                )
              }
            }
            else if(this.props.triggerItemId === TRIGGER_TYPES.TWILIO_DAILY_MESSAGE_CAP){
              if(
                item.key_index === ACTION_TYPES.TWILIO_EMAIL_USER ||
                item.key_index === ACTION_TYPES.TWILIO_SMS_USER
              ){
                data.push(
                  <MenuItem 
                    className="dropdownhelper-menuitem-class color__trigger_select" 
                    onClick={()=>{this.changeValue(item.key_index,item)}} 
                    key={index} 
                    value={item.key_index}
                  >
                    {item.name}
                  </MenuItem>
                )
              }
            }
            else{
              if(
                item.key_index !== ACTION_TYPES.TWILIO_PAUSE_ALL_CAMPAIGN &&
                item.key_index !== ACTION_TYPES.TWILIO_PAUSE_ALL_COMMUNICATION &&
                item.key_index !== ACTION_TYPES.TWILIO_EMAIL_USER &&
                item.key_index !== ACTION_TYPES.TWILIO_SMS_USER &&
                item.key_index !== ACTION_TYPES.TWILIO_PAUSE_SPECIFIC_CAMPAIGN
              ){
                  data.push(
                    <MenuItem 
                      className="dropdownhelper-menuitem-class color__trigger_select" 
                      onClick={()=>{this.changeValue(item.key_index,item)}} 
                      key={index} 
                      value={item.key_index}
                    >
                      {item.name}
                    </MenuItem>
                  )
              }
            }
          })
            // data = allActionItem.map((data, index) => {
            //     return <MenuItem className="dropdownhelper-menuitem-class" onClick={()=>{this.changeValue(data.key_index,data)}} key={index} value={data.key_index}>
            //         {data.name}
            //     </MenuItem>
            // })
        }
        return data;
    };

    show = () =>{
        this.setState({
            show : !this.state.show
        })
    };

    hide = () =>{
        this.setState({
            show : false
        })
    };

    getActionById = (id,item) => {
        return item.filter( action => action.id === id);
    }

    changeValue =  (id,action) => {
        if(id !== this.state.selected_action_id) {
            this.setState({
                selected_action_id: id,
                selected_action: action,
                selected_campaign_to_take_action: null,
                selected_tag_to_take_action: null,
                selected_list_to_take_action: null,
                selected_list_option: null,
                selected_collaborator_to_take_action: null,
                selected_pipeline_to_take_action: null,
                selected_stage_to_take_action: null,
                defaultCampaignOptions: [],
                defaultTagOptions: [],
                defaultListOptions: [],
                defaultCollaboratorOptions: [],
                defaultPipelineOptions: [],
                defaultStageOptions: [],
                deal_value:0,
                deal_closing_day:0,
                createOrMove:0,
                deleteType:1,
                webhook_url: '',
                zapier_webhook_url: '',
                sms:'',
                mySmsCount: 0,
                fromNumber:null,
                to_sms_type:0,
                to_sms:null,
                to_email_type:0,
                to_email:null,
                from_email: null,
                email_subject: '',
                email_body: '',
                textEditor : '',
                note: '',
                title:'',
                task_description:'',
                time:'12:00 PM',
                duration:'00:15',
                taskTitle:'call',
                task_id:1,
                assign_to:null,
                day: 1,
                integration_id: null,
                selected_source_to_take_action: null,
            },() => this.setTriggerActionValue());

            this.props.setTriggerAction(id);

            if (id === ACTION_TYPES.ADD_TO_CAMPAIGN || id === ACTION_TYPES.REMOVE_FROM_SINGLE_CAMPAIGN) {
                this.getDefaultOptionForCampaign();
            } else if (id === ACTION_TYPES.REMOVE_FROM_ALL_CAMPAIGN) {
                //Nothing
            }  else if (id === ACTION_TYPES.REMOVE_ALL_TAG) {
                //Nothing
            } else if (id === ACTION_TYPES.REMOVE_TAG || id === ACTION_TYPES.ADD_TAG) {
                this.getDefaultOptionForTag();
            } else if (id === ACTION_TYPES.ADD_LIST || id === ACTION_TYPES.REMOVE_LIST) {
                this.getDefaultOptionForList();
            } else if (id === ACTION_TYPES.ADD_COLLABORATOR || id === ACTION_TYPES.REMOVE_COLLABORATOR) {
                this.getDefaultOptionForCollaborator();
                this.getTemplateCollaboratorList();

            } else if (id === ACTION_TYPES.CHANGE_STAGE || id === ACTION_TYPES.REMOVE_DEAL ) {
                this.getDefaultOptionForPipeline();
            } else if (id === ACTION_TYPES.SEND_SMS) {
                this.setState({
                    fromNumber: this.props.allVirtualNumber[0],
                })
            } else if (id === ACTION_TYPES.SEND_EMAIL) {
                if (this.props.agency.email_provider !== 0 && this.props.profile.email_provider == 1) {
                    this.setState({
                        from_email: {
                            label: JSON.parse(this.props.profile.nylas_email_provider_info).email,
                            value: JSON.parse(this.props.profile.nylas_email_provider_info).email,
                        }
                    })
                }else{
                    this.setState({
                        from_email: this.props.allEmail[0],
                    })
                }
            } else if (id === ACTION_TYPES.ADD_NOTE) {
                //console.log(id);
            } else if (id === ACTION_TYPES.ADD_TASK) {
                //console.log(id);
            } else if (id === ACTION_TYPES.WEBHOOK_URL || id === ACTION_TYPES.ZAPIER_WEBHOOK) {
                //console.log(id);
            } else if (id === ACTION_TYPES.ADD_SOURCE) {
                this.getDefaultOptionForSource();
            } else{
                //console.log("nai");
            }
        }
        this.hide();
    };

	loadData = (actionValue,from) => {
        let trigger_action_id = '';
        let trigger_action_value = '';
		if(from == 'did'){
		    if(actionValue){
                trigger_action_id = actionValue.user_trigger_action_item_id;
                trigger_action_value = actionValue.user_trigger_action_item_value;
                let data = this.getActionById(trigger_action_id, this.props.allActionItem);
                this.changeValue(trigger_action_id, data[0]);
            }
		}else{
            trigger_action_id = actionValue.action_id;
            trigger_action_value = actionValue;
            let data = this.getActionById(trigger_action_id, this.props.allActionItem);
            this.setState({
                selected_action_id: trigger_action_id,
                selected_action: data[0],
            })
		}

        if (trigger_action_id === ACTION_TYPES.ADD_TO_CAMPAIGN || trigger_action_id === ACTION_TYPES.REMOVE_FROM_SINGLE_CAMPAIGN) {
            let campaignData = this.getActionById(trigger_action_value.campaign_id, this.props.allCampaign);
            if(campaignData[0]){
                this.setState({
                    selected_campaign_to_take_action: {
                        label: campaignData[0].title,
                        value: trigger_action_value.campaign_id
                    }
                })
            }

        }
        // else if (trigger_action_id === ACTION_TYPES.REMOVE_FROM_ALL_CAMPAIGN) {
        //
        // }
        else if (trigger_action_id === ACTION_TYPES.REMOVE_TAG || trigger_action_id === ACTION_TYPES.ADD_TAG) {
            let tagData = this.getActionById(trigger_action_value.tag_id, this.props.allTag);
            if(tagData[0]){
                this.setState({
                    selected_tag_to_take_action: {
                        label: tagData[0].name,
                        value: trigger_action_value.tag_id
                    }
                })
            }

        } else if (trigger_action_id === ACTION_TYPES.ADD_LIST || trigger_action_id === ACTION_TYPES.REMOVE_LIST) {
            let listData = this.getActionById(trigger_action_value.list_id, this.props.allList);
            if(listData[0]){
                this.setState({
                    selected_list_to_take_action: {
                        label: listData[0].title,
                        value: trigger_action_value.list_id
                    }
                })
            }

        } else if (trigger_action_id === ACTION_TYPES.ADD_COLLABORATOR || trigger_action_id === ACTION_TYPES.REMOVE_COLLABORATOR) {
            let collaboratorData = this.getActionById(trigger_action_value.collaborator_id, this.props.allCollaborator);
            if(collaboratorData[0]){
                this.setState({
                    selected_collaborator_to_take_action: {
                        label: collaboratorData[0].full_name,
                        value: trigger_action_value.collaborator_id
                    }
                })
            }

        } else if (trigger_action_id === ACTION_TYPES.CHANGE_STAGE) {
            let PipelineData = this.getActionById(trigger_action_value.pipeline_id,this.props.allPipeline);

            let StageData = this.getActionById(trigger_action_value.stage_id,this.props.allStage);
            //change start
            let allOptions = [];
            if (this.props.allPipeline.length > 0) {
                allOptions = this.props.allPipeline.map((data, index) => {
                    return {
                        value: data.id,
                        label: data.title
                    }
                });
            }
            // console.log(allOptions)
            this.setState({
                defaultPipelineOptions: allOptions
            })
            //change end

            if(PipelineData[0]){
                this.setState({
                    selected_pipeline_to_take_action:{
                        label: PipelineData[0].title,
                        value: trigger_action_value.pipeline_id}
                });
            }
            this.setState({
                deal_value: trigger_action_value.deal_value,
                createOrMove: trigger_action_value.createOrMove,
                deal_closing_day: trigger_action_value.deal_closing_day
            });
            if(StageData[0]){

                this.setState({
                    selected_stage_to_take_action:{
                        label: StageData[0].stage,
                        value: trigger_action_value.stage_id}
                })
            }
            this.getDefaultOptionForStage(trigger_action_value.pipeline_id);

        } else if (trigger_action_id === ACTION_TYPES.REMOVE_DEAL) {
            let PipelineData = this.getActionById(trigger_action_value.pipeline_id,this.props.allPipeline);

            let StageData = this.getActionById(trigger_action_value.stage_id,this.props.allStage);
            //change start
            let allOptions = [];
            if (this.props.allPipeline.length > 0) {
                allOptions = this.props.allPipeline.map((data, index) => {
                    return {
                        value: data.id,
                        label: data.title
                    }
                });
            }
            // console.log(allOptions)
            this.setState({
                defaultPipelineOptions: allOptions
            })
            //change end

            if(PipelineData[0]){
                this.setState({
                    selected_pipeline_to_take_action:{
                        label: PipelineData[0].title,
                        value: trigger_action_value.pipeline_id}
                });
            }
            this.setState({
                deleteType: trigger_action_value.deleteType
            });
            if(StageData[0]){

                this.setState({
                    selected_stage_to_take_action:{
                        label: StageData[0].stage,
                        value: trigger_action_value.stage_id}
                })
            }
            this.getDefaultOptionForStage(trigger_action_value.pipeline_id);

        } else if (trigger_action_id === ACTION_TYPES.SEND_SMS) {
            this.setState({
                fromNumber: {
                    label: trigger_action_value.from,
                    value: trigger_action_value.from
                },
                sms: trigger_action_value.sms,
                to_sms_type: trigger_action_value.to_type ? trigger_action_value.to_type : 0,
                to_sms: trigger_action_value.to ? trigger_action_value.to : null,
                mySmsCount: trigger_action_value.mySmsCount
            });
        } else if (trigger_action_id === ACTION_TYPES.SEND_EMAIL) {
            this.setState({
                from_email: {
                    label: trigger_action_value.from,
                    value: trigger_action_value.from
                },
                to_email_type: trigger_action_value.to_type ? trigger_action_value.to_type : 0,
                to_email: trigger_action_value.to ? trigger_action_value.to : null,
                email_subject: trigger_action_value.email_subject,
                email_body: trigger_action_value.email_body
            });
        } else if (trigger_action_id === ACTION_TYPES.ADD_NOTE) {
            this.setState({note: trigger_action_value.note})
        } else if (trigger_action_id === ACTION_TYPES.WEBHOOK_URL) {
            this.setState({webhook_url: trigger_action_value.webhook_url})
        } else if (trigger_action_id === ACTION_TYPES.ZAPIER_WEBHOOK) {
            this.setState({zapier_webhook_url: trigger_action_value.zapier_webhook_url})
        } else if (trigger_action_id === ACTION_TYPES.ADD_TASK) {
            this.setState({
                title: trigger_action_value.title,
                task_description: trigger_action_value.task_description,
                time: trigger_action_value.time,
                duration: trigger_action_value.duration,
                day: trigger_action_value.day,
                assign_to: trigger_action_value.assign_to === undefined ? null : trigger_action_value.assign_to,
                task_id: trigger_action_value.task_type
            })
        } else if (trigger_action_id === ACTION_TYPES.THIRD_PARTY_INTEGRATION) {
            this.setState({
                integration_id: trigger_action_value.integration_id
            });
        } else if (trigger_action_id === ACTION_TYPES.ADD_SOURCE) {
            
            let sourceData = this.getActionById(trigger_action_value.source_id, this.props.allSources);
            if(sourceData[0]){
                this.setState({
                    selected_source_to_take_action: {
                        label: sourceData[0].source_title,
                        value: sourceData[0].id
                    }
                })
            }
        }
        else if (trigger_action_id === ACTION_TYPES.TWILIO_EMAIL_USER) {
          this.setState({
            twilio_email_send_user: trigger_action_value?.value,
            email_subject: trigger_action_value?.subject,
            email_body: trigger_action_value?.message
          })
        }
        else if (trigger_action_id === ACTION_TYPES.TWILIO_SMS_USER) {
          this.setState({
            twilio_sms_send_user: trigger_action_value?.value,
            sms: trigger_action_value?.message,
          })
        }else if (trigger_action_id === ACTION_TYPES.SMART_WEBHOOK) {
            this.setState({
                smart_webhook_data: trigger_action_value?.smart_webhook_data,
            })
        }
        
    }

    handlePersonalize = (options)=>{

        var start = this.myTextInput.selectionStart;
        var end = this.myTextInput.selectionEnd;
        var text = this.myTextInput.value;
        var before = text.substring(0, start);
        var after  = text.substring(end, text.length);
        var newText = (before + options.value + after);
        this.myTextInput.value = newText;
        this.myTextInput.selectionStart = this.myTextInput.selectionEnd = start + newText.length;
        this.myTextInput.focus();
        this.setState({
            sms:newText
        },() => this.setTriggerActionValue())
    };

    handleEmailPersonalize = (e)=>{

        var start = this.myTextInput.selectionStart;
        var end = this.myTextInput.selectionEnd;
        var text = this.myTextInput.value;
        var before = text.substring(0, start);
        var after  = text.substring(end, text.length);
        var newText = (before + e.currentTarget.getAttribute("data-url") + after);
        this.myTextInput.value = newText;
        this.myTextInput.selectionStart = this.myTextInput.selectionEnd = start + newText.length;
        this.myTextInput.focus();
        this.setState({
            email_body:newText
        },() => this.setTriggerActionValue())
    };

    calculateSmsCount = ()=> {

        let totalCharc = this.state.sms ? this.state.sms.length:0;
        let smsCount = 0;
        if (totalCharc > 160) {
            var remain = (totalCharc - 160);
            smsCount = remain > 150 ? Math.ceil(totalCharc / 150) : 2;
        } else {
            smsCount = 1
        }
        this.setState({
            mySmsCount: smsCount
        },() => this.setTriggerActionValue())
    };

    changeSms = (event)=> {
      this.setState({
          sms: (event.target.value) ? event.target.value : ''
      } ,() => {
          this.setTriggerActionValue();
          this.calculateSmsCount();
      })
    };

    setEmailBody = (html) =>{
        this.setState({
            email_body : html
        },() => this.setTriggerActionValue());
    };

    onChangeHandle = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        },() => this.setTriggerActionValue());
    }

    onChangeEmailSubject = (subject) => {
        this.setState({
            email_subject : subject
        },() => this.setTriggerActionValue());
    }

    onChangeSMSType = (e) => {
        this.setState({
            to_sms_type: e.target.value
        },() => this.setTriggerActionValue());
    }

    onChangeEmailType = (e) => {
        this.setState({
            to_email_type: e.target.value
        },() => this.setTriggerActionValue());
    }

    onChangeDealCreateOrMove = (e) => {
        this.setState({
            createOrMove: e.target.value
        },() => this.setTriggerActionValue());
    }

    onChangeDealDelete = (e) => {
        this.setState({
            deleteType: e.target.value
        },() => this.setTriggerActionValue());
    }

    dataChange = (options) =>{
        this.setState( person =>({
            email_subject : person.email_subject +""+options.value
        }),() => this.setTriggerActionValue());
    };

    taskHandler = (val) => {
        this.setState((st)=>({ task_id : val }),() => this.setTriggerActionValue());
    };

    timeHandler = (val) => {
        this.setState({ time: val },() => this.setTriggerActionValue());
    };

    durationHandler = (val) => {
        this.setState({ duration: val },() => this.setTriggerActionValue());
    };

    setTriggerActionValue = () => {
      const {selected_action_id} = this.state;
      const {RowIndex,actionValue} = this.props;
        if (selected_action_id === ACTION_TYPES.ADD_TO_CAMPAIGN || selected_action_id === ACTION_TYPES.REMOVE_FROM_SINGLE_CAMPAIGN) {
            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id:selected_action_id,
                    campaign_id:this.state.selected_campaign_to_take_action ? this.state.selected_campaign_to_take_action.value : null,
                },
                index: RowIndex
            });
        } else if (selected_action_id === ACTION_TYPES.REMOVE_FROM_ALL_CAMPAIGN) {

            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id:selected_action_id,
                },
                index: RowIndex
            });

        }  else if (selected_action_id === ACTION_TYPES.REMOVE_ALL_TAG) {

            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id:selected_action_id,
                },
                index: RowIndex
            });

        } else if (selected_action_id === ACTION_TYPES.REMOVE_TAG || selected_action_id === ACTION_TYPES.ADD_TAG) {
            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id:selected_action_id,
                    tag_id:this.state.selected_tag_to_take_action ? this.state.selected_tag_to_take_action.value : null,
                },
                index: RowIndex
            });
        }  else if (selected_action_id === ACTION_TYPES.ADD_LIST || selected_action_id === ACTION_TYPES.REMOVE_LIST) {
            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id:selected_action_id,
                    list_id:this.state.selected_list_to_take_action ? this.state.selected_list_to_take_action.value : null,
                },
                index: RowIndex
            });
        } else if (selected_action_id === ACTION_TYPES.ADD_COLLABORATOR || selected_action_id === ACTION_TYPES.REMOVE_COLLABORATOR) {
            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id:selected_action_id,
                    collaborator_id:this.state.selected_collaborator_to_take_action ? this.state.selected_collaborator_to_take_action.value : null,
                },
                index: RowIndex
            });
        } else if (selected_action_id === ACTION_TYPES.CHANGE_STAGE) {
            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id:selected_action_id,
                    createOrMove:parseInt(this.state.createOrMove,10),
                    pipeline_id:this.state.selected_pipeline_to_take_action ? this.state.selected_pipeline_to_take_action.value : null,
                    stage_id:this.state.selected_stage_to_take_action ? this.state.selected_stage_to_take_action.value : null,
                    deal_value: parseInt(this.state.deal_value, 10),
                    deal_closing_day: parseInt(this.state.deal_closing_day, 10)
                },
                index: RowIndex
            });
        } else if (selected_action_id === ACTION_TYPES.REMOVE_DEAL) {
            let pipeline_id = this.state.selected_pipeline_to_take_action ? this.state.selected_pipeline_to_take_action.value : null;
            let stage_id = this.state.selected_stage_to_take_action ? this.state.selected_stage_to_take_action.value : null;
            if(this.state.deleteType == '2') {
                pipeline_id = null;
                stage_id = null;
            }
            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id: selected_action_id,
                    deleteType: parseInt(this.state.deleteType,10),
                    pipeline_id: pipeline_id,
                    stage_id: stage_id
                },
                index: RowIndex
            });
        } else if (selected_action_id === ACTION_TYPES.SEND_SMS) {
            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id:selected_action_id,
                    from: this.state.fromNumber ? this.state.fromNumber.value:null,
                    sms:this.state.sms,
                    to_type:parseInt(this.state.to_sms_type, 10),
                    to:this.state.to_sms,
                    mySmsCount:this.state.mySmsCount,
                },
                index: RowIndex
            });
        } else if (selected_action_id === ACTION_TYPES.SEND_EMAIL) {
            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id:selected_action_id,
                    from:this.state.from_email.value,
                    to_type:parseInt(this.state.to_email_type, 10),
                    to:this.state.to_email,
                    email_subject:this.state.email_subject,
                    email_body:this.state.email_body
                },
                index: RowIndex
            });
        } else if (selected_action_id === ACTION_TYPES.ADD_NOTE) {
            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id:selected_action_id,
                    note:this.state.note
                },
                index: RowIndex
            });
        } else if (selected_action_id === ACTION_TYPES.WEBHOOK_URL) {
            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id:selected_action_id,
                    webhook_url:this.state.webhook_url
                },
                index: RowIndex
            });
        }else if (selected_action_id === ACTION_TYPES.SMART_WEBHOOK) {
            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id:selected_action_id,
                    smart_webhook_data: this.state.smart_webhook_data
                },
                index: RowIndex
            });
        } else if (selected_action_id === ACTION_TYPES.ZAPIER_WEBHOOK) {
            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id:selected_action_id,
                    zapier_webhook_url:this.state.zapier_webhook_url
                },
                index: RowIndex
            });
        } else if (selected_action_id === ACTION_TYPES.ADD_TASK) {
            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id:selected_action_id,
                    title:this.state.title,
                    assign_to:parseInt(this.state.assign_to, 10),
                    task_description:this.state.task_description,
                    day:parseInt(this.state.day, 10),
                    time:this.state.time,
                    duration:this.state.duration,
                    task_type:this.state.task_id
                },
                index: RowIndex
            });
        } else if (selected_action_id === ACTION_TYPES.THIRD_PARTY_INTEGRATION) {
            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : '',
                    action_id:selected_action_id,
                    integration_id:parseInt(this.state.integration_id, 10),
                },
                index: RowIndex
            });
        } else if (selected_action_id === ACTION_TYPES.ADD_SOURCE) {
            let sourceId = this.state.selected_source_to_take_action ? this.state.selected_source_to_take_action.value : null;
            this.props.setTriggerActionValue({
                data:{
                    id: actionValue ? actionValue.id : null,
                    action_id: selected_action_id,
                    source_id: sourceId,
                },
                index: RowIndex
            });
        }
        else if (selected_action_id === ACTION_TYPES.TWILIO_PAUSE_ALL_CAMPAIGN) {
          this.props.setTriggerActionValue({
            data:{
              id: actionValue ? actionValue.id : null,
              action_id: selected_action_id,
              value: ""
            },
            index: RowIndex
          });
        }
        else if (selected_action_id === ACTION_TYPES.TWILIO_PAUSE_ALL_COMMUNICATION) {
          this.props.setTriggerActionValue({
            data:{
              id: actionValue ? actionValue.id : null,
              action_id: selected_action_id,
              value: ""
            },
            index: RowIndex
          });
        }
        else if (selected_action_id === ACTION_TYPES.TWILIO_PAUSE_SPECIFIC_CAMPAIGN) {
          this.props.setTriggerActionValue({
            data:{
              id: actionValue ? actionValue.id : null,
              action_id: selected_action_id,
              value: ""
            },
            index: RowIndex
          });
        }
        else if (selected_action_id === ACTION_TYPES.TWILIO_EMAIL_USER) {
          this.props.setTriggerActionValue({
            data:{
              id: actionValue ? actionValue.id : null,
              action_id: selected_action_id,
              value: this.state.twilio_email_send_user,
              subject: this.state.email_subject,
              message: this.state.email_body,
            },
            index: RowIndex
          });
        }
        else if (selected_action_id === ACTION_TYPES.TWILIO_SMS_USER) {
          this.props.setTriggerActionValue({
            data:{
              id: actionValue ? actionValue.id : null,
              action_id: selected_action_id,
              value: this.state.twilio_sms_send_user,
              message:this.state.sms,
            },
            index: RowIndex
          });
        }

    }

    setTextEditor = (html) => {
        if(html != this.state.textEditor) {
            this.setState({
                email_body: html
            },() => this.setTriggerActionValue());
        }
    };

    handleCheck = (checkValue) => {
        //console.log(checkValue);
        /*this.setState((state) => ({checked: !state.checked}));*/
    };

    sendZapierWebhook = (e) => {
        e.preventDefault();
        let { zapier_webhook_url } = this.state;
        fetch('https://webhook-api.pypepro.io/v1/zapier/webhook?'+ new URLSearchParams({ url: zapier_webhook_url, user_id: Utils.getAccountData('userId') }),
        {
            mode : 'no-cors',
            method: 'POST',
        }).then(response => {
            window.showNotification('SUCCESS','Request Sent!');
        }).catch((error) => {
            window.showNotification('ERROR','Error!');
        });
    }

    sendTestWebHook = (e) => {
        e.preventDefault();
        let { webhook_url } = this.state;

        if (webhook_url) {
            sendWebhookTest('POST','https://core-api.pypepro.io/v1/webhook/test',{webhookURL : webhook_url}).then(res => {
                if(res.status === 'SUCCESS') {
                    window.showNotification('SUCCESS','Request Sent!');
                } else {
                    try {
                        window.showNotification('ERROR',JSON.parse(res.message).error.message);
                    } catch(ex) {
                        window.showNotification('ERROR', 'Something went wrong, please try with different url.');
                    }
                }
            });
        } else {
            window.showNotification('ERROR','Please insert a valid url');
        }
    }

    handleVirtualNumber = (options)=>{
        this.setState({
            fromNumber : options,
        },() => this.setTriggerActionValue());
    }

    handleFromEmail = (options) =>{
        this.setState({
            from_email : options
        },() => this.setTriggerActionValue());
    }

    onOpenWebhookBuilderModal = (data=null) => {

        if (data){
            this.setState({
                openSmartWebhookBuilder: true,
                selectedSmartWebhook: data
            });
        }else {
            this.setState({
                openSmartWebhookBuilder: true
            });
        }
    }

    onCloseWebhookBuilderModal = () => {
        this.setState({
            selectedSmartWebhook: null,
            isSubmittingAddSmartWebhookForm: false,
            openSmartWebhookBuilder: false
        });
    }

    handleSubmitWebhookForm = (payload) => {
        if (this.state.selectedSmartWebhook && this.state.selectedSmartWebhook.id){
            payload.id = this.state.selectedSmartWebhook.id;
        }
        this.setState({
            smart_webhook_data: payload,
            openSmartWebhookBuilder: false
        },() => this.setTriggerActionValue());
    }

    render() {
        const {allActionItem} = this.props;
        let showClass = "";

        if(this.state.show){
            showClass = "dropdown-wrapper open"
        } else {
            showClass = "dropdown-wrapper"
        }

        let list = [];
        for (let i = 0; i <= 365; i++) {
            list.push(i);
        }
        let days = list.map(function(data,index){
            return(
                <MenuItem className="dropdownhelper-menuitem-class" key={index} value={data} >{data == 0 ? 'Not defined':data}</MenuItem>
            )
        });

        // console.log(this.props.allVirtualNumber)
        let oldVirtualNumber = this.props.allVirtualNumber;
        let newVirtualNumber = [{id: 0, label: 'User default number', value: '{{user_default_number}}'}, ...oldVirtualNumber];

        return (
            <div className={"action__item trigger__item"}>
                    <div className="d-flex justify-content-between align-content-center">
                        <h4 className="trigger__action_title">Which action should we perform?</h4>
                        <Button className="action__delete" onClick={(e) => this.props.remover(e,this.props.RowIndex)} data-index={this.props.RowIndex}>{iconList.redDeleteIcon}</Button>
                    </div>
                    <FormControl className="customFormControl">
                        <MaterialSelect
                            value={ this.state.selected_action_id }
                            displayEmpty
                            className={ `trigger__select coloredText` }
                            inputProps={ { 'aria-label': 'Without label' } }
                        >
                        <MenuItem value={0} disabled>
                            Select action item
                        </MenuItem>
                        {this.getEachAction(allActionItem)}
                        </MaterialSelect>
                    </FormControl>

                    {(this.state.selected_action_id === ACTION_TYPES.ADD_TO_CAMPAIGN || this.state.selected_action_id === ACTION_TYPES.REMOVE_FROM_SINGLE_CAMPAIGN) &&
                        <div>
                            <If condition={!Utils.getAccountData('starterPack')}>
                                <div className="action__item trigger__item">
                                    <div className="col s12">
                                        <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                            <div>
                                                Select a Campaign <span className="red-text">*</span>
                                            </div>
                                        </InputLabel>
                                    </div>
                                    <div className="col">
                                        <div className="form-group m-form__group ">
                                            <div className="form-group m-form__group">
                                                <Creatable
                                                    styles={customEmailPersonalizedStyle}
                                                    isClearable={false}
                                                    options={this.state.defaultCampaignOptions}
                                                    value={this.state.selected_campaign_to_take_action}
                                                    isMulti={false}
                                                    onChange={this.actionAddToCampaign} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </If>
                            <If condition={Utils.getAccountData('starterPack')}>
                                <div className="row py-3 lead-flow-card">
                                    <div className="col s12">
                                        <p className={'text-danger'}>
                                            <b>Sorry!</b> To use this feature please upgrade your package to starter-pro or standard package. <b><a href={PACKAGE_PAGE_URL}>Buy Now</a></b>
                                        </p>
                                    </div>
                                </div>
                            </If>
                        </div>

                    }
                    {(this.state.selected_action_id === ACTION_TYPES.REMOVE_TAG || this.state.selected_action_id === ACTION_TYPES.ADD_TAG) &&
                    <div className="action__item trigger__item">
                        <div className="col s12">
                            <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                <div>
                                    Select a Tag <span className="red-text">*</span>
                                </div>
                            </InputLabel>
                        </div>
                        <div className="col">
                            <div className="form-group m-form__group ">
                                <div className="form-group m-form__group">
                                    <Creatable
                                        styles={customEmailPersonalizedStyle}
                                        isClearable={false}
                                        options={this.state.defaultTagOptions}
                                        value={this.state.selected_tag_to_take_action}
                                        isMulti={false}
                                        onChange={this.actionAddToTag} />
                                </div>
                            </div>
                        </div>
                    </div>
                    }{(this.state.selected_action_id === ACTION_TYPES.ADD_LIST || this.state.selected_action_id === ACTION_TYPES.REMOVE_LIST) &&
                    <div className="action__item trigger__item">
                        <div className="col s12">
                            <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                <div>
                                    Select a List <span className="red-text">*</span>
                                </div>
                            </InputLabel>
                        </div>
                        <div className="col">
                            <div className="form-group m-form__group ">
                                <div className="form-group m-form__group">
                                    <Creatable
                                        styles={customEmailPersonalizedStyle}
                                        isClearable={false}
                                        options={this.state.defaultListOptions}
                                        value={this.state.selected_list_to_take_action}
                                        isMulti={false}
                                        onChange={this.actionAddToList} />
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {(this.state.selected_action_id === ACTION_TYPES.ADD_COLLABORATOR || this.state.selected_action_id === ACTION_TYPES.REMOVE_COLLABORATOR) &&
                    <div className="action__item trigger__item">
                        <div className="col s12">
                            <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                <div>
                                    Select a Collaborator <span className="red-text">*</span>
                                </div>
                            </InputLabel>
                        </div>
                        <div className="col">
                            <div className="form-group m-form__group ">
                                <div className="form-group m-form__group">
                                    <Creatable
                                        styles={customEmailPersonalizedStyle}
                                        isClearable={false}
                                        options={this.state.templateCollaboratorList}
                                        value={this.state.selected_collaborator_to_take_action}
                                        isMulti={false}
                                        onChange={this.actionAddToCollaborator} />
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {(this.state.selected_action_id === ACTION_TYPES.CHANGE_STAGE) &&
                        <div>
                            <div className="row mt-2 lead-flow-card">
                                <div className="col s12">
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="create_or_move" name="create_or_move" value={this.state.createOrMove} onChange={this.onChangeDealCreateOrMove}>
                                            <BootstrapTooltip arrow title="It means it will keep previous all deal of contact and will create one new deal of contact" placement="right">
                                                <FormControlLabel value={0} control={<Radio />} label="Create New" />
                                            </BootstrapTooltip>
                                            <BootstrapTooltip arrow title="It means it will remove previous all deal of contact and will create one new deal of contact" placement="right">
                                                <FormControlLabel value={1} control={<Radio />} label="Move Existing" />
                                            </BootstrapTooltip>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row lead-flow-card">
                                <div className="col s12">
                                    <div className="form-group m-form__group">
                                        <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                            <div>
                                                Deal Value
                                            </div>
                                        </InputLabel>
                                        <TextField name="deal_value" value={this.state.deal_value} onChange={this.onChangeHandle}  type="number" min={0} className="input_field_with_border" placeholder="Deal value" />
                                    </div>
                                </div>
                                <div className="col s12">
                                    <div className="form-group m-form__group">
                                        <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                            <div>
                                                Estimated Closing Day
                                            </div>
                                        </InputLabel>
                                        <MaterialSelect 
                                            displayEmpty name="deal_closing_day"
                                            value={this.state.deal_closing_day}
                                            className={ `trigger__select coloredText` }
                                            inputProps={ { 'aria-label': 'Without label' } }
                                            onChange={this.onChangeHandle}>
                                            {days}
                                        </MaterialSelect>
                                    </div>
                                </div>
                            </div>

                            <div className="row lead-flow-card">
                                <div className="col s12">
                                    <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                        <div>
                                            Select a Pipeline <span className="red-text">*</span>
                                        </div>
                                    </InputLabel>
                                </div>
                                <div className="col s12">
                                    <div className="form-group m-form__group ">
                                        <div className="form-group m-form__group">
                                            <Creatable
                                                styles={customEmailPersonalizedStyle}
                                                isClearable={false}
                                                options={this.state.defaultPipelineOptions}
                                                value={this.state.selected_pipeline_to_take_action}
                                                isMulti={false}
                                                onChange={this.actionAddToPipeline} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row lead-flow-card">
                                <div className="col s12">
                                    <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                        <div>
                                            Select a Stage <span className="red-text">*</span>
                                        </div>
                                    </InputLabel>
                                </div>
                                <div className="col s12">
                                    <div className="form-group m-form__group ">
                                        <div className="form-group m-form__group">
                                            <Creatable
                                                styles={customEmailPersonalizedStyle}
                                                isClearable={false}
                                                options={this.state.defaultStageOptions}
                                                value={this.state.selected_stage_to_take_action}
                                                isMulti={false}
                                                onChange={this.actionAddToStage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {(this.state.selected_action_id === ACTION_TYPES.REMOVE_DEAL) &&
                        <div>
                            <div className="row py-3 lead-flow-card">
                                <div className="col s12">
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" className="trigger__action_title">Send To</FormLabel>
                                        <RadioGroup aria-label="deal_delete_type" name="deal_delete_type" value={this.state.deleteType} onChange={this.onChangeDealDelete}>
                                            <BootstrapTooltip arrow title="It means it will delete your all deals of contact from specific stage" placement="right">
                                                <FormControlLabel value={1} control={<Radio />} label="Remove From Stage" />
                                            </BootstrapTooltip>
                                            <BootstrapTooltip arrow title="It means it will delete all deal of contact from all stage" placement="right">
                                                <FormControlLabel value={2} control={<Radio />} label="Remove All" />
                                            </BootstrapTooltip>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>
                            <If condition={this.state.deleteType == 1}>
                                <div className="div">
                                    <div className="row lead-flow-card">
                                        <div className="col s12">
                                            <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                                <div>
                                                    Select a Pipeline <span className="red-text">*</span>
                                                </div>
                                            </InputLabel>
                                        </div>
                                        <div className="col s12">
                                            <div className="form-group m-form__group ">
                                                <div className="form-group m-form__group">
                                                    <Creatable
                                                        styles={customEmailPersonalizedStyle}
                                                        isClearable={false}
                                                        options={this.state.defaultPipelineOptions}
                                                        value={this.state.selected_pipeline_to_take_action}
                                                        isMulti={false}
                                                        onChange={this.actionAddToPipeline} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row lead-flow-card">
                                        <div className="col s12">
                                            <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                                <div>
                                                    Select a Stage <span className="red-text">*</span>
                                                </div>
                                            </InputLabel>
                                        </div>
                                        <div className="col s12">
                                            <div className="form-group m-form__group ">
                                                <div className="form-group m-form__group">
                                                    <Creatable
                                                        styles={customEmailPersonalizedStyle}
                                                        isClearable={false}
                                                        options={this.state.defaultStageOptions}
                                                        value={this.state.selected_stage_to_take_action}
                                                        isMulti={false}
                                                        onChange={this.actionAddToStage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </If>

                        </div>
                    }
                    {(this.state.selected_action_id === ACTION_TYPES.SEND_SMS) &&
                        <div>
                            <If condition={!Utils.getAccountData('starterPack') && !Utils.getAccountData('starterProPack')}>
                                <div className="row lead-flow-card pt-2">
                                    <div className="col s12">
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend" className="trigger__action_title">Send To</FormLabel>
                                            <RadioGroup aria-label="gender" name="to_sms_type" value={this.state.to_sms_type} onChange={this.onChangeSMSType}>
                                                <BootstrapTooltip arrow title="It means it will send the text to contact" placement="right">
                                                    <FormControlLabel value={0} control={<Radio />} label="Contact" />
                                                </BootstrapTooltip>
                                                <BootstrapTooltip arrow title="It means it will send the text to specific number that you put bellow" placement="right">
                                                    <FormControlLabel value={1} control={<Radio />} label="Number" />
                                                </BootstrapTooltip>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                                <If condition={this.state.to_sms_type==1}>
                                    <div className="row lead-flow-card">
                                        <div className="col s12">
                                            <div className="form-group m-form__group">
                                                <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                                    <div>
                                                        To Number <span className="red-text">*</span>
                                                    </div>
                                                </InputLabel>
                                                <TextField name="to_sms" value={this.state.to_sms} onChange={this.onChangeHandle}  type="text" className="input_field_with_border" placeholder="To Number" />
                                            </div>
                                        </div>
                                    </div>
                                </If>

                                <div className="row lead-flow-card">
                                    <div className="col s12 mb-3">
                                        <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                            <div>
                                                From Number <span className="red-text">*</span>
                                            </div>
                                        </InputLabel>
                                        <Select
                                            styles={customEmailPersonalizedStyle}
                                            name="virtualNumber"
                                            value = {this.state.fromNumber}
                                            options={newVirtualNumber}
                                            onChange={this.handleVirtualNumber}
                                            className="email-form-control p-0"
                                            isDisabled={false}
                                        />
                                    </div>

                                    <div className="col s12">
                                        <div className="email__actions actions__append">
                                            <FormGroup className="mt20px">
                                                <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-end justify-content-between">
                                                    <div>
                                                        Message <span className="red-text">*</span>
                                                    </div>
                                                    <If condition={this.props.personalize}>
                                                        <Select
                                                            styles={customMessagePersonalizedStyle}
                                                            name="personalize"
                                                            value = {null}
                                                            options={this.props.personalize}
                                                            placeholder={'Personalize'}
                                                            onChange={this.handlePersonalize}
                                                        />
                                                    </If>
                                                </InputLabel>
                                                
                                                <TextField
                                                    inputRef={ref => this.myTextInput = ref}
                                                    name="message" 
                                                    className="messageTextField"  
                                                    multiline
                                                    placeholder="Add Sms here"
                                                    value={this.state.sms}
                                                    onChange={this.changeSms}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="col pb-2">
                                        <div className="d-flex">
                                            <span>
                                                Total SMS :
                                                <span className="count-character"> <b>{this.state.sms ? this.state.sms.length:0}</b> </span>
                                                / <span className="count-message"> <b>{this.state.mySmsCount}</b></span>
                                               <If condition={this.state.sms && this.state.sms.length > 500}>
                                                    <div className="text-danger error-message">Maximum Character 500 Allowed</div>
                                               </If>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </If>
                            <If condition={!Utils.getAccountData('standardPack')}>
                                <div className="row py-3 lead-flow-card">
                                    <div className="col s12">
                                        <p className={'text-danger'}>
                                            <b>Sorry!</b> To use this feature please upgrade your package to standard package. <b><a href={PACKAGE_PAGE_URL}>Buy Now</a></b>
                                        </p>
                                    </div>
                                </div>
                            </If>

                        </div>

                    }
                    {(this.state.selected_action_id === ACTION_TYPES.SEND_EMAIL) &&
                        <div>
                            <If condition={!Utils.getAccountData('starterPack')}>
                                <div className="row lead-flow-card py-3">
                                    <div className="col s12">
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend" className="trigger__action_title">Send To</FormLabel>
                                            <RadioGroup aria-label="gender" name="gender1" value={this.state.to_email_type} onChange={this.onChangeEmailType}>
                                                <BootstrapTooltip arrow title="It means it will send the mail to contact" placement="right">
                                                    <FormControlLabel value={0} control={<Radio />} label="Contact" />
                                                </BootstrapTooltip>
                                                <BootstrapTooltip arrow title="It means it will send the mail to specific email that you put bellow" placement="right">
                                                    <FormControlLabel value={1} control={<Radio />} label="An Email" />
                                                </BootstrapTooltip>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <If condition={this.state.to_email_type==1}>
                                        <div className="col s12">
                                            <div className="form-group m-form__group">
                                                <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                                    <div>
                                                        To Email <span className="red-text">*</span>
                                                    </div>
                                                </InputLabel>
                                                <TextField name="to_email" value={this.state.to_email} onChange={this.onChangeHandle} type="email" className="input_field_with_border" placeholder="To Email" />
                                            </div>
                                        </div>
                                    </If>
                                    <div className="col s12 mt-3">
                                        <div className="form-group m-form__group">
                                            <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                                <div>
                                                    From <span className="red-text">*</span>
                                                </div>
                                            </InputLabel>
                                            <Select
                                                styles={customEmailPersonalizedStyle}
                                                value = {this.state.from_email}
                                                options={this.props.allEmail}
                                                onChange={this.handleFromEmail}
                                                className="email-form-control p-0"
                                                isDisabled={(this.props.agency && this.props.agency.email_provider !== 0 && this.props.profile.email_provider == 1) ? true : false }
                                            />
                                        </div>
                                    </div>
                                    <div className="col s12 mt-3">
                                        <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                            <div>
                                                Subject <span className="red-text">*</span>
                                            </div>
                                        </InputLabel>
                                    </div>
                                    <div className="col s12 email-form">
                                        <div className="email-form-field">
                                            <div className="d-flex row">
                                                <div className="field col s6">
                                                    <TextField type="text" name="email_subject" ref="subject" onChange={ this.onChangeHandle} placeholder="Enter mail subject" className="input_field_with_border" value= {this.state.email_subject} />
                                                </div>
                                                <div className="select col s6">
                                                    <If condition={this.props.personalize}>
                                                        <Select
                                                            styles={customPersonalizedStyle}
                                                            name="personalize"
                                                            value = {null}
                                                            options={this.props.personalize}
                                                            placeholder={'Personalize'}
                                                            onChange={this.dataChange}
                                                        />
                                                    </If>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col s12">
                                        <div className="form-group m-form__group">
                                            <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                                <div>
                                                    Message <span className="red-text">*</span>
                                                </div>
                                            </InputLabel>
                                            {/*<textarea ref={ref => this.myTextInput = ref} onChange={this.onChangeHandle} name="email_body" cols="30" rows="5"
                                          className="form-control" placeholder="Add message here"
                                          value={this.state.email_body}/>*/}
                                            <TextEditor templates={this.props.email_templates}
                                                        customFields = {this.props.personalize}
                                                        action={ this.setTextEditor}
                                                        userSignature={this.props.profile.show_signature == 1 ? this.props.profile.signature : ''}
                                                        appointmentUrl={this.props.profile.show_appointment_url == 1 ? this.props.profile.appointment_url : ''}
                                                        handleCheck={(checkValue) => this.handleCheck(checkValue)}
                                                        updateComponent={this.state.email_body}
                                                        editSettings={this.props.actionValue ? true : false}
                                                        isShowSaveTemplate ={true}
                                                        isShowSaveTemplateOption={false}
                                                        fromNewTrigger={true}
                                                        subjectAction={this.onChangeEmailSubject}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </If>
                            <If condition={Utils.getAccountData('starterPack')}>
                                <div className="row py-3 lead-flow-card">
                                    <div className="col s12">
                                        <p className={'text-danger'}>
                                            <b>Sorry!</b> To use this feature please upgrade your package to starter-pro or standard package. <b><a href={PACKAGE_PAGE_URL}>Buy Now</a></b>
                                        </p>
                                    </div>
                                </div>
                            </If>
                        </div>
                    }
                    {(this.state.selected_action_id === ACTION_TYPES.ADD_NOTE) &&
                        <div className="email__actions actions__append">
                            <FormGroup className="mt20px">
                                <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                    Note <span className="red-text">*</span>
                                </InputLabel>
                                <TextField
                                    name="note" 
                                    className="messageTextField"  
                                    multiline
                                    placeholder="Add note here"
                                    value={this.state.note}
                                    onChange={this.onChangeHandle}
                                />
                            </FormGroup>
                        </div>
                    }
                    {(this.state.selected_action_id === ACTION_TYPES.WEBHOOK_URL) &&
                        <div className="action__item trigger__item">
                            <div className="col s12 m-form">
                                <div className="form-group m-form__group">
                                    <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                        <div>
                                            Webhook URL <span className="red-text">*</span>
                                        </div>
                                    </InputLabel>
                                    <BootstrapTooltip arrow placement="top-start" title={
                                        <>
                                            <Typography color="inherit">Examples : </Typography>
                                            <p>https://example.com</p>
                                            <p>https://www.example.com</p>
                                            <p>https://www.example.com/webhook</p>
                                        </>
                                    }>
                                        <TextField name="webhook_url" value={this.state.webhook_url} onChange={this.onChangeHandle}  type="text" className="input_field_with_border" placeholder="Add webhook link here" />
                                    </BootstrapTooltip>
                                    <div>
                                        <span className="m-form__help">Json data will be send. Sample given bellow</span><br/>
                                        {Utils.getAccountData('userId')==1?
                                        <code>
                                            [<br/>
                                            &emsp;{'{'}<br/>
                                                &emsp;&emsp;first_name: "John",<br/>
                                                &emsp;&emsp;last_name: "Doe",<br/>
                                                &emsp;&emsp;email: "example@example.com",<br/>
                                                &emsp;&emsp;phone: "2202215656",<br/>
                                                &emsp;&emsp;address: "23 SRS Colony, New York",<br/>
                                                &emsp;&emsp;city: "New Baily",<br/>
                                                &emsp;&emsp;state: "New York",<br/>
                                                &emsp;&emsp;zip: 23233,<br/>
                                                &emsp;&emsp;deal_value: 200000,<br/>
                                                &emsp;&emsp;custom_field_1: value1,<br/>
                                                &emsp;&emsp;custom_field_2: value2,<br/>
                                                &emsp;&emsp;......<br/>
                                            &emsp;{'}'}<br/>
                                            ]<br/>
                                        </code>
                                        :
                                        <code>
                                            [<br/>
                                            &emsp;{'{'}<br/>
                                                &emsp;&emsp;first_name: "John",<br/>
                                                &emsp;&emsp;last_name: "Doe",<br/>
                                                &emsp;&emsp;email: "example@example.com",<br/>
                                                &emsp;&emsp;phone: "2202215656",<br/>
                                                &emsp;&emsp;address: "23 SRS Colony, New York",<br/>
                                                &emsp;&emsp;city: "New Baily",<br/>
                                                &emsp;&emsp;state: "New York",<br/>
                                                &emsp;&emsp;zip: 23233,<br/>
                                                &emsp;&emsp;deal_value: 200000<br/>
                                            &emsp;{'}'}<br/>
                                            ]<br/>
                                        </code>
                                        }
                                      
                                        <a href="#!" onClick={(e) => this.sendTestWebHook(e)} className="btn btn-primary float-right m--font-primary m-form__help mt-3">Send Sample Test</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {(this.state.selected_action_id === ACTION_TYPES.SMART_WEBHOOK) &&
                        <div className="action__item trigger__item">
                            <div className="col s12 m-form">
                                <div className="form-group m-form__group">
                                    <div className="trigger__action_title mbSpace10 d-flex align-items-center">
                                        {
                                            this.state.smart_webhook_data ?
                                                <div className="webhook-card">
                                                    <h6 title={this.state.smart_webhook_data.title} className="truncate-fix">{ this.state.smart_webhook_data.title }</h6>
                                                    <p title={this.state.smart_webhook_data.url} className="truncate-fix">{ this.state.smart_webhook_data.url }</p>
                                                    <span onClick={()=>{ this.onOpenWebhookBuilderModal(this.state.smart_webhook_data) }} className="webhook-card-edit">{iconList.editIcon}</span>
                                                </div> :
                                                <button onClick={()=>{ this.onOpenWebhookBuilderModal() }}  className="create-button-oip">
                                                    {iconList.bluePlusIcon}
                                                    Create Smart Webhook
                                                </button>
                                        }
                                    </div>

                                </div>
                            </div>

                            <SmartWebhookDrawer
                                data={this.state.selectedSmartWebhook}
                                open={this.state.openSmartWebhookBuilder}
                                isSubmitting={this.state.isSubmittingAddSmartWebhookForm}
                                onClose={this.onCloseWebhookBuilderModal}
                                onSubmit={this.handleSubmitWebhookForm}
                            />
                        </div>
                    }
                    {(this.state.selected_action_id === ACTION_TYPES.ZAPIER_WEBHOOK) &&
                        <div className="action__item trigger__item">
                            <div className="col s12 m-form">
                                <div className="form-group m-form__group">
                                    <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                        <div>
                                            Webhook URL <span className="red-text">*</span>
                                        </div>
                                    </InputLabel>
                                    <BootstrapTooltip arrow placement="top-start" title={
                                        <>
                                            <Typography color="inherit">Examples : </Typography>
                                            <p>https://example.com</p>
                                            <p>https://www.example.com</p>
                                            <p>https://www.example.com/webhook</p>
                                        </>
                                    }>
                                        <TextField name="zapier_webhook_url" value={this.state.zapier_webhook_url} onChange={this.onChangeHandle}  type="text" className="input_field_with_border" placeholder="Add webhook link here" />
                                    </BootstrapTooltip>
                                    <div>
                                        <span className="m-form__help">Json data will be send. Sample given bellow</span><br/>
                                        {Utils.getAccountData('userId')==1?
                                        <code>
                                            [<br/>
                                            &emsp;{'{'}<br/>
                                                &emsp;&emsp;first_name: "John",<br/>
                                                &emsp;&emsp;last_name: "Doe",<br/>
                                                &emsp;&emsp;email: "example@example.com",<br/>
                                                &emsp;&emsp;phone: "2202215656",<br/>
                                                &emsp;&emsp;address: "23 SRS Colony, New York",<br/>
                                                &emsp;&emsp;city: "New Baily",<br/>
                                                &emsp;&emsp;state: "New York",<br/>
                                                &emsp;&emsp;zip: 23233,<br/>
                                                &emsp;&emsp;deal_value: 200000,<br/>
                                                &emsp;&emsp;custom_field_1: value1,<br/>
                                                &emsp;&emsp;custom_field_2: value2,<br/>
                                                &emsp;&emsp;......<br/>
                                            &emsp;{'}'}<br/>
                                            ]<br/>
                                        </code>
                                        :
                                        <code>
                                            [<br/>
                                            &emsp;{'{'}<br/>
                                                &emsp;&emsp;first_name: "John",<br/>
                                                &emsp;&emsp;last_name: "Doe",<br/>
                                                &emsp;&emsp;email: "example@example.com",<br/>
                                                &emsp;&emsp;phone: "2202215656",<br/>
                                                &emsp;&emsp;address: "23 SRS Colony, New York",<br/>
                                                &emsp;&emsp;city: "New Baily",<br/>
                                                &emsp;&emsp;state: "New York",<br/>
                                                &emsp;&emsp;zip: 23233,<br/>
                                                &emsp;&emsp;deal_value: 200000<br/>
                                            &emsp;{'}'}<br/>
                                            ]<br/>
                                        </code>
                                        }
                                      
                                        <a href="#!" onClick={(e) => this.sendZapierWebhook(e)} className="btn btn-primary float-right m--font-primary m-form__help mt-3">Send Sample Test</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {(this.state.selected_action_id === ACTION_TYPES.ADD_TASK) &&
                        <div className="">
                            <div className="row">
                                <div className="col s12">
                                    <SelectTask taskHandler={this.taskHandler} task_id={this.state.task_id} taskTitle={''}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12">
                                    <div className="form-group m-form__group">
                                        <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                            <div>
                                                Title <span className="red-text">*</span>
                                            </div>
                                        </InputLabel>
                                        <TextField name="title" value={this.state.title} onChange={this.onChangeHandle}  type="text" className="input_field_with_border" placeholder="Enter Title" />
                                    </div>
                                </div>
                                <div className="col s12">
                                    <div className="form-group m-form__group">
                                        <FormGroup className="mt20px">
                                            <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                                <div>
                                                    Description
                                                </div>
                                            </InputLabel>
                                            <TextField
                                                name="task_description" 
                                                className="messageTextField"  
                                                multiline
                                                placeholder="Add description here"
                                                value={this.state.task_description}
                                                onChange={this.onChangeHandle}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="col s12">
                                    <div className="form-group m-form__group">
                                        <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                            <div>
                                                Assign To
                                            </div>
                                        </InputLabel>
                                        <MaterialSelect 
                                            displayEmpty name="assign_to" 
                                            value={Number.isNaN(this.state.assign_to) ? null : this.state.assign_to} 
                                            className={ `trigger__select coloredText` }
                                            inputProps={ { 'aria-label': 'Without label' } }
                                            onChange={this.onChangeHandle}>
                                            <MenuItem value={null}>Select an user</MenuItem>
                                            {this.getCollaboratorList()}
                                        </MaterialSelect>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12">
                                    <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                        <div>
                                            Day
                                        </div>
                                    </InputLabel>
                                    <MaterialSelect 
                                        displayEmpty name="day" 
                                        value={this.state.day} 
                                        className={ `trigger__select coloredText` }
                                        inputProps={ { 'aria-label': 'Without label' } }
                                        onChange={this.onChangeHandle}>
                                        {days}
                                    </MaterialSelect>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12">
                                    <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                        <div>
                                            Time
                                        </div>
                                    </InputLabel>
                                    <GetTimeOptions value={this.state.time} timeHandler = {this.timeHandler} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12">
                                    <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                        <div>
                                            Duration (hh:mm)
                                        </div>
                                    </InputLabel>
                                    
                                    <GetDurationOptions value={this.state.duration} durationHandler = {this.durationHandler} />
                                </div>
                            </div>
                        </div>
                    }

                    {(this.state.selected_action_id === ACTION_TYPES.THIRD_PARTY_INTEGRATION) &&
                        <div className="mt-2">
                            <div className="row">
                                <div className="col s12">
                                    <div className="form-group m-form__group">
                                        <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                            <div>
                                                Select the platform <span className="red-text">*</span>
                                            </div>
                                        </InputLabel>

                                        <MaterialSelect 
                                            displayEmpty name="integration_id" 
                                            value={Number.isNaN(this.state.integration_id) ? null : this.state.integration_id} 
                                            className={ `trigger__select coloredText` }
                                            inputProps={ { 'aria-label': 'Without label' } }
                                            onChange={this.onChangeHandle}>
                                            <MenuItem value={null}>Select a platform</MenuItem>
                                            {this.getIntegrationList()}
                                        </MaterialSelect>
                                        
                                        {/* <select name="integration_id" value={this.state.integration_id} onChange={this.onChangeHandle} className="form-control">
                                            <option value="null" selected={true}>Select a platform</option>
                                            {this.getIntegrationList()}
                                        </select> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {(this.state.selected_action_id === ACTION_TYPES.ADD_SOURCE) &&
                        <div>
                            <If condition={!Utils.getAccountData('starterPack')}>
                                <div className="action__item trigger__item">
                                    <div className="col s12">
                                        <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                            <div>
                                                Select a Source <span className="red-text">*</span>
                                            </div>
                                        </InputLabel>
                                    </div>
                                    <div className="col">
                                        <div className="form-group m-form__group ">
                                            <div className="form-group m-form__group" key={this.state.selected_source_to_take_action}>
                                                <Creatable
                                                    styles={customEmailPersonalizedStyle}
                                                    isClearable={false}
                                                    options={this.state.defaultSourceOptions}
                                                    value={this.state.selected_source_to_take_action}
                                                    isMulti={false}
                                                    onChange={this.actionAddToSource}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </If>
                            <If condition={Utils.getAccountData('starterPack')}>
                                <div className="row py-3 lead-flow-card">
                                    <div className="col s12">
                                        <p className={'text-danger'}>
                                            <b>Sorry!</b> To use this feature please upgrade your package to starter-pro or standard package. <b><a href={PACKAGE_PAGE_URL}>Buy Now</a></b>
                                        </p>
                                    </div>
                                </div>
                            </If>
                        </div>

                    }

                    {/* for twilio error code */}
                    {(
                      this.state.selected_action_id === ACTION_TYPES.TWILIO_SMS_USER
                    ) &&
                        <div className="action__item trigger__item">
                          <div className="col s12 m-form">
                            <div className="form-group m-form__group">
                              <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                <div>
                                  Add user's contacts <span className="red-text">*</span>
                                </div>
                              </InputLabel>
                              <TextField name="twilio_sms_send_user" value={this.state.twilio_sms_send_user} onChange={this.onChangeHandle}  type="text" className="input_field_with_border" placeholder="Add users contacts by comma separator" />
                              <span><small>Add multiple contacts by comma separator</small></span>
                            </div>
                            
                            <div className="email__actions actions__append">
                              <FormGroup className="mt20px">
                                <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-end justify-content-between">
                                    <div>
                                      Message <span className="red-text">*</span>
                                    </div>
                                    <If condition={this.props.personalize}>
                                      <Select
                                        styles={customMessagePersonalizedStyle}
                                        name="personalize"
                                        value = {null}
                                        options={this.props.personalize}
                                        placeholder={'Personalize'}
                                        onChange={this.handlePersonalize}
                                      />
                                    </If>
                                </InputLabel>
                                
                                <TextField
                                  inputRef={ref => this.myTextInput = ref}
                                  name="message" 
                                  className="messageTextField"  
                                  multiline
                                  placeholder="Add Sms here"
                                  value={this.state.sms}
                                  onChange={this.changeSms}
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                    }
                    {(
                      this.state.selected_action_id === ACTION_TYPES.TWILIO_EMAIL_USER
                    ) &&
                        <div className="action__item trigger__item">
                          <div className="col s12 m-form">
                            <div className="form-group m-form__group">
                              <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                <div>
                                  Add user's email <span className="red-text">*</span>
                                </div>
                              </InputLabel>
                              <TextField name="twilio_email_send_user" value={this.state.twilio_email_send_user} onChange={this.onChangeHandle}  type="text" className="input_field_with_border" placeholder="Add users email by comma separator" />
                              <span><small>Add multiple emails by comma separator</small></span>
                            </div>

                            <div className="col s12 mt-3">
                              <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                <div>
                                  Subject <span className="red-text">*</span>
                                </div>
                              </InputLabel>
                            </div>

                            <div className="col s12 email-form">
                              <div className="email-form-field">
                                <div className="d-flex row">
                                  <div className="field col s6">
                                    <TextField type="text" name="email_subject" ref="subject" onChange={ this.onChangeHandle} placeholder="Enter mail subject" className="input_field_with_border" value= {this.state.email_subject} />
                                  </div>
                                  <div className="select col s6">
                                    <If condition={this.props.personalize}>
                                      <Select
                                        styles={customPersonalizedStyle}
                                        name="personalize"
                                        value = {null}
                                        options={this.props.personalize}
                                        placeholder={'Personalize'}
                                        onChange={this.dataChange}
                                      />
                                    </If>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col s12">
                              <div className="form-group m-form__group">
                                <InputLabel className="trigger__action_title mbSpace10 d-flex align-items-center">
                                  <div>
                                    Message <span className="red-text">*</span>
                                  </div>
                                </InputLabel>
                                  <TextEditor templates={this.props.email_templates}
                                              customFields = {this.props.personalize}
                                              action={ this.setTextEditor}
                                              userSignature={this.props.profile.show_signature == 1 ? this.props.profile.signature : ''}
                                              appointmentUrl={this.props.profile.show_appointment_url == 1 ? this.props.profile.appointment_url : ''}
                                              handleCheck={(checkValue) => this.handleCheck(checkValue)}
                                              updateComponent={this.state.email_body}
                                              editSettings={this.props.actionValue ? true : false}
                                              isShowSaveTemplate ={true}
                                              isShowSaveTemplateOption={false}
                                              fromNewTrigger={true}
                                              subjectAction={this.onChangeEmailSubject}
                                  />
                              </div>
                            </div>

                          </div>
                        </div>
                    }
            </div>
        );
    }
}
const EachAction = connect(mapStateToProps, mapDispatchToProps)(SingleAction);
export default EachAction;
