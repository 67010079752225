const CONTACT_EMAIL = 'CONTACT EMAIL';
const FIRST_NAME = 'FIRST NAME';
const LAST_NAME = 'LAST NAME';
const MY_VIRTUAL_NUMBER = 'My virtual number';
const MY_COMPANY = 'My Company';
const MY_NAME = 'My Name';
const MY_FIRST_NAME = 'My First Name';
const MY_LAST_NAME = 'My Last Name';
const MY_PHONE = 'My Phone';
const MY_SIGNATURE = 'My Signature';
const MY_APPOINTMENT_URL = 'My Appointment URL';
const TODAY = 'Today Date';
const TOMORROW = 'Tomorrow Date';
const CONTACT_COUNTRY = 'Property Country';
const CONTACT_STATE = 'Property State';
const CONTACT_STREET_ADDRESS = 'Property Street Address';
const CONTACT_URL = 'Property URL';
const CONTACT_ZIP_CODE = 'Property Zip Code';
const CONTACT_PHONE_NUMBER = 'Contact Phone Number';
const CONTACT_CITY = 'Contact City';
const CONTACT_DEAL_VALUE = 'Contact Deal Value';
const CONTACT_BIRTH_DATE = 'Contact Birth Date';
const CONTACT_ANNIVERSARY_DATE = 'Contact Anniversary Date';
const CONTACT_COMPANY_NAME = 'Contact Company Name';




const CoreConstants  = {
    TRIGGER_STATUS : {
        STATUS_PAUSE :0,
        STATUS_START :1,
        STATUS_DELETE:2,
    },

    WITHOUT_CUSTOM_FIELDS : [
        {
          value: 'blue',
          label: 'Personalized',
          color: '#f2f2f2',
          isDisabled: true
        },
        {
            label : FIRST_NAME ,
            value : '[[first_name]]'
        },
        {
            label : LAST_NAME ,
            value :  '[[last_name]]'
        },
        {
            label : CONTACT_EMAIL,
            value : '[[email]]'
        },
        {
            label : CONTACT_PHONE_NUMBER,
            value : '[[contact_phone_number]]'
        },
        {
            label : MY_VIRTUAL_NUMBER,
            value : '[[virtual_number]]'
        },
        {
            label : MY_COMPANY ,
            value : '[[company]]'
        },
        {
            label : MY_NAME ,
            value : '[[my_name]]'
        },
        {
            label : MY_FIRST_NAME ,
            value : '[[my_first_name]]'
        },
        {
            label : MY_LAST_NAME ,
            value : '[[my_last_name]]'
        },
        {
            label : MY_PHONE ,
            value : '[[my_phone]]'
        },
        {
            label :MY_SIGNATURE,
            value  : '[[my_signature]]'
        },
        {
            label :MY_APPOINTMENT_URL,
            value  : '[[appointment_url]]'
        },
        {
            label : TODAY,
            value  : '[[today]]'
        },
        {
            label : TOMORROW,
            value  : '[[tomorrow]]'
        },
        {
            label : CONTACT_COUNTRY,
            value  : '[[contact_country]]'
        },
        {
            label : CONTACT_STATE,
            value : '[[contact_state]]'
        },
        {
            label : CONTACT_CITY,
            value : '[[contact_city]]'
        },
        {
            label : CONTACT_STREET_ADDRESS,
            value : '[[contact_street_address]]'
        },
        {
            label : CONTACT_COMPANY_NAME,
            value : '[[contact_company_name]]'
        },
        {
            label : CONTACT_URL,
            value : '[[contact_url]]'
        },
        {
            label :CONTACT_ZIP_CODE,
            value : '[[contact_zip_code]]'
        },
        {
            label :CONTACT_DEAL_VALUE,
            value : '[[contact_deal_value]]'
        },
        {
            label :CONTACT_BIRTH_DATE,
            value : '[[contact_birth_date]]'
        },
        {
            label :CONTACT_ANNIVERSARY_DATE,
            value : '[[contact_anniversary_date]]'
        },
        {
          value: 'blue',
          label: 'User Personalize Fields',
          color: '#f2f2f2',
          isDisabled: true
        }
    
    ]
}

export default CoreConstants;

export const ACTION_TYPES = {
    ADD_TO_CAMPAIGN : 1,
    REMOVE_FROM_SINGLE_CAMPAIGN : 2,
    REMOVE_FROM_ALL_CAMPAIGN : 3,
    REMOVE_TAG : 4,
    ADD_TAG : 5,
    SEND_EMAIL : 6,
    SEND_SMS : 7,
    ADD_NOTE : 8,
    ADD_TASK:9,
    CHANGE_STAGE:10,
    ADD_COLLABORATOR:11,
    REMOVE_COLLABORATOR:12,
    WEBHOOK_URL:13,
    ADD_LIST : 14,
    REMOVE_LIST : 15,
    REMOVE_ALL_TAG : 16,
    REMOVE_DEAL : 17,
    THIRD_PARTY_INTEGRATION : 18,
    ADD_SOURCE: 19,
    ZAPIER_WEBHOOK : 20,
    TWILIO_PAUSE_ALL_CAMPAIGN : 21,
    TWILIO_PAUSE_ALL_COMMUNICATION : 22,
    TWILIO_EMAIL_USER : 23,
    TWILIO_SMS_USER : 24,
    TWILIO_PAUSE_SPECIFIC_CAMPAIGN : 25,
    SMART_WEBHOOK: 29,
}

export const TRIGGER_TYPES = {
    ADDED_CAMPAIGN : 1,
    CONTACT_REPLIED : 2,
    CUSTOMER_BOOKED_APPOINTMENT : 3,
    STAGE_CHANGE : 4,
    ADDED_TAG : 5,
    ANNIVERSARY_REMAINDER : 6,
    TASK_REMAINDER : 7,
    APPOINTMENT : 8,
    STALE_OPPORTUNITY : 9,
    DEAL_STATUS_CHANGE : 10,
    FORM_SUBMISSION : 11,
    BIRTHDAY_REMAINDER : 12,
    CALENDLY_APPOINTMENT_BOOKED : 13,
    INCOMING_CALL : 14,
    APPOINTMENT_STATUS_CHANGE : 15,
    MISSED_CALL : 16,
    OUTGOING_CALL : 17,
    SMS_ACCOUNT_SUSPENDED : 19,
    TWILIO_MESSAGE_FILTER : 20,
    TWILIO_DAILY_MESSAGE_CAP : 21,
    CREATE_ACTIVITY : 18
};

export const ANNIVERSARY_REMAINDER= {
    MONTH_IS: 0,
    DAY_IS: 1,
    BEFORE_NO_OF_DAYS: 2,
    AFTER_NO_OF_DAYS: 3,
    CURRENT_DAY: 4,
    HAS_TAG: 5,
    DOES_NOT_HAS_TAG: 6
}

export const BIRTHDAY_REMAINDER= {
    MONTH_IS: 0,
    DAY_IS: 1,
    BEFORE_NO_OF_DAYS: 2,
    AFTER_NO_OF_DAYS: 3,
    CURRENT_DAY: 4,
    HAS_TAG: 5,
    DOES_NOT_HAS_TAG: 6
}

export const CONTACT_TAG= {
    TAG_ADDED: 0,
    TAG_REMOVED: 1
}

export const CONTACT_REPLIED = {
    IN_CAMPAIGN : 0,
    REPLY_CHANNEL : 1,
    REPLY_KEYWORD : 2,
    CONTAIN_PHRASE : 3,
    EXACT_MATCH_PHRASE : 4,
};

export const TASK_REMAINDER= {
    BEFORE_NO_OF_DAYS: 0,
    AFTER_NO_OF_DAYS: 1,
}

export const APPOINTMENT= {
    IN_CALENDAR: 0,
    APPOINTMENT_STATUS_IS: 1
}

export const APPOINTMENT_STATUS = {
    MARK_AS : 0
}

export const STALE_OPPORTUNITY = {
    DURATION_IN_DAYS : 0,
    IN_ACTIVE_CAMPAIGN : 1,
    HAS_TAG : 2,
    DOES_NOT_HAVE_TAG : 3,
};

export const CONTACT_SOURCE = {
    ADD_SOURCE: 19,
}

export const SEND_TO = {
    CONTACT : 0,
    ADDRESS : 1
}

export const PACKAGE_PAGE_URL = '/user/profile/packages';
export const FILTER_FOR_CREATE_ACTIVITY= [ "Call Activity",
    "Appointment Activity",
    "Task Activity",
    "Email Activity",
    "Deadline Activity",
    "Followup Activity",
    "Other Activity"
]
