import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const fetchTriggerList = async (payload) => {
    return sendRequest("post", Api.triggerList, payload);
}

export const fetchAgencyTemplates = async (payload) => {
    return sendRequest("post", Api.agencyTemplates, payload);
}

export const changeTriggerStatus = async (payload) => {
    return sendRequest("post", Api.changeStatus, payload);
}

export const duplicateTrigger = async (payload) => {
    return sendRequest("post", Api.duplicate, payload);
}

export const cloneTriggerAndAssignUser = async (payload) => {
    return sendRequest("post", Api.cloneTriggerAndAssignToUser, payload);
}

export const moveRuleToFolder = async (payload) => {
    return sendRequest("post", Api.moveRuleToFolder, payload);
}

export const moveRuleFromFolder = async (payload) => {
    return sendRequest("post", Api.moveRuleFromFolder, payload);
}

export const updateTitle = async (payload) => {
    return sendRequest("post", Api.edit, payload);
}

export const addNewRule = async (payload) => {
    return sendRequest("post", Api.add, payload);
}

export const createShareLink = async (payload) => {
    return sendRequest("post", Api.shareLinkCreate, payload);
}

export const shareTriggerLink = async (payload) => {
    return sendRequest("post", Api.shareWithFriend, payload);
}

export const deleteTrigger = async (payload) => {
    return sendRequest("post", Api.remove, payload);
}

export const addNewFolder = async (payload) => {
    return sendRequest("post", Api.folderAdd, payload);
}

export const editFolder = async (payload) => {
    return sendRequest("post", Api.folderEdit, payload);
}

export const removeFolder = async (payload) => {
    return sendRequest("post", Api.folderRemove, payload);
}

export const cloneAgencyTemplates = async (payload) => {
    return sendRequest("post", Api.cloneAgencyTemplates, payload);
}

export const getTriggerSetting = async (payload) => {
    return sendRequest("post", Api.settingGet, payload);
}

export const saveTriggerSetting = async (payload) => {
    return sendRequest("post", Api.settingSave, payload);
}

export const getCollaboratorDropdownList = async (payload) => {
    return sendRequest("post", Api.getCollaboratorListDropdown, payload);
}