import React,{Component} from 'react';
import {connect} from "react-redux";
import EachAction from "./EachAction";
import { removeActionValue, resetTriggerActions, setRequiredError, setTriggerAction, setTriggerActionValue } from '../../actions/triggerAction';
import CoreConstants, { TRIGGER_TYPES } from '../../constants/CoreConstants';
import { fetchCustomFields } from '../../actions/commonAction';
import If from 'if-else-react';
import { Button } from '@material-ui/core';
import { iconList } from '../globals/IconList';
import SmartWebhookDrawer from "../trigger/smartWebhookBuilder/SmartWebhookDrawer";

const mapStateToProps = state => {
    return {
        loading : state.triggerSettingReducer.loading,
        triggerRule: state.triggerSettingReducer.triggerRule,
        personalize : state.triggerSettingReducer.personalize,
        data : state.triggerSettingReducer.data,
        previousActionValue : state.triggerSettingReducer.previousActionValue,
        triggerActionValue : state.triggerSettingReducer.triggerActionValue,
        triggerItemId: state.triggerSettingReducer.triggerItemId,
        needToResetActions: state.triggerSettingReducer.needToResetActions,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        setTriggerAction: (triggerActionId) => dispatch(setTriggerAction(triggerActionId)),
        removeActionValue: (rowIndex) => dispatch(removeActionValue(rowIndex)),
        setTriggerActionValue: (param) => dispatch(setTriggerActionValue(param)),
        setRequiredError: (param) => dispatch(setRequiredError(param)),
        fetchCustomFields: (param,callBack) => dispatch(fetchCustomFields(param,callBack)),
        resetTriggerActions: (param) => dispatch(resetTriggerActions(param)),
    };
}

class Action extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eachActionRow: [],
            show : false,
            selected_action_id: 0,
            selected_action: {},
            selected_action_values: {},
            selected_campaign_to_take_action: null,
            selected_tag_to_take_action: null,
            selected_collaborator_to_take_action: null,
            selected_pipeline_to_take_action: null,
            selected_stage_to_take_action: null,
            defaultCampaignOptions : [],
            defaultTagOptions : [],
            defaultCollaboratorOptions : [],
            defaultPipelineOptions : [],
            defaultStageOptions : [],
            deal_value:'',
            deal_closing_day:'',
            sms: '',
            mySmsCount: 0,
            to_sms:'',
            personalize:[],
            note: '',
            from_email: '',
            to_email: '',
            email_subject : '',
            email_body : '',
            textEditor : '',
            webhook_url: '',
            zapier_webhook_url: '',
            title:'',
            task_description:'',
            time:'12:00PM',
            duration:'00:15',
            taskTitle:'call',
            task_id:1,
            triggerItemId: this.props.triggerItemId,
            selectedSmartWebhookTemplate: null,
            isSubmittingAddSmartWebhookForm: false,
            openSmartWebhookBuilder: false
        };
    }

    componentDidMount() {
        this.dataLoad();
        const {triggerRule} = this.props;
        if(triggerRule.rule_trigger_action != null) {
            let eachActionRow = [];
            if(triggerRule.rule_trigger_action.user_trigger_actions.length > 0){
                triggerRule.rule_trigger_action.user_trigger_actions.map( (action,index) => {
                    eachActionRow = eachActionRow.concat(<EachAction remover={this.removeAction} key={index} RowIndex={index}/>)
                });
                this.setState({
                    eachActionRow: eachActionRow
                });
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if(nextProps.needToResetActions){
        this.setState({eachActionRow: []})
        this.props.resetTriggerActions(false)
      }
    }

    dataLoad = () => {
        window.globalCustomFieldWithPersonalized({
            get_custom_data: true,
            get_personalized_data: true,
            field_name: ['*'],
            }).then(res => {
                let customFields = [];
                let personalizedFields = res.data.personalized_field
                let customFieldDatas = res.data.custom_field;
                personalizedFields.forEach((row)=>{
                    customFields.push({
                    label : row.title,
                    value : row.personalize_tag,
                });
                })

                customFields.push({
                    value: 'blue',
                    label: 'User Customize Fields',
                    color: '#f2f2f2',
                    isDisabled: true
                });

                customFieldDatas.forEach((row)=>{
                    customFields.push({
                    label : row.title,
                    value : row.personalize_tag,
                });
                })

                this.setState({
                    personalize: CoreConstants.WITHOUT_CUSTOM_FIELDS.concat(customFields)
                });

            })

        // this.props.fetchCustomFields({for_custom_field_update:true},(res) => {
        //     let customFields = [];
        //     // res.data.data.data.forEach((row) => {
        //     //     customFields.push({
        //     //         label : row.title,
        //     //         value : row.personalize_tag,
        //     //     });
        //     // });

        //     this.setState({
        //         personalize: CoreConstants.WITHOUT_CUSTOM_FIELDS.concat(customFields)
        //     });
        // });
    };

    removeAction = (e, rowIndex) => {
        e.preventDefault();
        if (rowIndex !== -1) {
            let eachActionRow = [...this.state.eachActionRow];
            eachActionRow.splice(rowIndex, 1);
            this.props.removeActionValue(rowIndex);
            this.setState({
                eachActionRow: eachActionRow
            });
        }
    };

    addNewAction = () => {
        let eachActionRow = this.state.eachActionRow;
        let rowIndex = eachActionRow.length;
        this.setState({
            eachActionRow: this.state.eachActionRow.concat(<EachAction newAction={true} remover={this.removeAction}
                                                                          key={rowIndex}
                                                                          RowIndex={rowIndex} />)
        });
    }

    render() {
        let newActionRowData = [];
        if(this.state.eachActionRow.length > 0){
            this.state.eachActionRow.map((row, index) => {
              let more = {}
              try{
                const row_props = row.props
                if(row_props.newAction !== undefined){
                  more['newAction'] = true;
                }
              }
              catch(error){}
              
                newActionRowData.push(<EachAction     actionValue={this.props.previousActionValue[index]}
                                                      selectedActionValue={this.props.triggerActionValue[index]}
                                                      remover={this.removeAction}
                                                      personalize={this.state.personalize}
                                                      key={index}
                                                      RowIndex={index}
                                                      {...more}
                                                    />);
            });
        }

        return (
            <>
                <If	condition={this.props.loading}>
                    <div className="col-12 loader-min-height d-flex justify-content-center">
                        <div className="align-self-center">
                            <div className="text-center">
                                {/* <Loader/> */}
                            </div>
                        </div>
                    </div>
                </If>
                <If	condition={this.props.data}>
                    <div className="trigger__setting_title alt w-100">
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <h4 className="trigger__action_title">Which action should we perform?</h4>
                        </div>
                    </div>
                    {newActionRowData}
                    <Button onClick={this.addNewAction} className="trigger__blueBtn trigger__btn trigger__blueBtn addAction">{iconList.bluePlusIcon} Add Action</Button>
                </If>
                <SmartWebhookDrawer
                    data={this.state.selectedSmartWebhookTemplate}
                    open={this.openSmartWebhookBuilder}
                    isSubmitting={this.state.isSubmittingAddSmartWebhookForm}
                    onClose={this.onCloseWebhookBuilderModal}
                    onSubmit={this.handleSubmitWebhookForm}
                />
            </>
        );
    }
}
const ActionItem = connect(mapStateToProps, mapDispatchToProps)(Action);
export default ActionItem;
